<template>
  <div
    style="height: 100%"
    ref="amenities"
    :class="{ right_content: language == 'ar' }"
  >
    <van-sticky>
      <van-nav-bar
        class="header"
        :title="stringList.amenities"
        @click-left="onClickLeft"
      >
        <template #left>
          <img
            src="../assets/img/back_new.png"
            style="width: 20px; height: 20px"
          />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="container">
      <div class="form-title">
        <span>{{ stringList.new_request }}</span>
      </div>
      <div class="form">
        <div v-if="showAddMore()">
          <template v-for="item in requestList">
            <div
              v-if="item.num > 0"
              class="form-item form-item2 required"
              :key="item.id"
            >
              <van-field
                v-if="language == 'en'"
                v-model="item.keyword"
                readonly
                placeholder="Select Request"
              ></van-field>
              <van-field
                v-else
                v-model="item[language]"
                readonly
                placeholder="Select Request"
              ></van-field>
              <van-stepper
                v-model="item.num"
                @change="changeNum($event, item.id)"
                min="0"
                theme="round"
                button-size="18"
                disable-input
              />
            </div>
          </template>
        </div>
        <div v-else>
          <div class="form-item required">
            <van-field
              readonly
              @click="addMore"
              :placeholder="stringList.select_common_request"
            ></van-field>
          </div>
        </div>

        <div class="form-item3" v-if="showAddMore()">
          <span class="text" @click="addMore">{{ stringList.more_items }}</span>
          <van-icon name="arrow" size="14px" @click="addMore" />
        </div>

        <div
          class="form-item required"
          style="margin-bottom: 20px"
          id="roomNumber"
        >
          <a-select
            show-search
            size="large"
            class="roomNum"
            :placeholder="stringList.room_placeholder"
            :getPopupContainer="() => $refs.amenities"
            :default-active-first-option="false"
            :filter-option="false"
            :not-found-content="null"
            @search="roomSearch"
            @change="roomChange"
            :value="roomNumber"
            @focus="clearRoomNumber"
            dropdownClassName="roomNumber"
            :notFoundContent="notFoundContent"
            ref="selectRoomNumber"
          >
            <template #suffixIcon>
              <img
                style="width: 15px"
                src="@/assets/img/hotel-please-clean.png"
              />
            </template>
            <a-select-option
              v-for="item in roomArray"
              :key="item.id"
              :value="item.name"
            >
                {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <van-row>
          <van-col span="11">
            <div class="form-item required">
              <van-field
                readonly
                :placeholder="stringList.today"
                :value="date"
                @click="() => (showDate = true)"
                :class="{ placeholderColor: date == '' }"
              >
                <template #right-icon>
                  <img
                    src="@/assets/img/calendar-line.png"
                    style="height: 15px"
                  />
                </template>
              </van-field>
            </div>
          </van-col>
          <van-col span="2"><div></div></van-col>
          <van-col span="11">
            <div class="form-item required">
              <van-field
                readonly
                :value="time"
                @click="selectTime"
                :placeholder="stringList.asap"
                :class="{ placeholderColor: time == '' }"
              >
                <template #right-icon>
                  <img src="@/assets/img/clock-time.png" style="height: 15px" />
                </template>
              </van-field>
            </div>
          </van-col>
        </van-row>
        <div class="form-item">
          <van-field
            v-model="notes"
            style="height: auto"
            type="textarea"
            rows="3"
            maxlength="200"
            :placeholder="stringList.comments_placeholder"
          />
        </div>

        <div class="form-item" v-if="showPhone">
          <span class="text" style="font-size: 14px">
            <span
              :class="{ right_text_item: language == 'ar' }"
              style="display: block"
              >{{ stringList.optional1 }}&nbsp;&nbsp;{{
                stringList.optional2
              }}</span
            >
          </span>
          <!-- <br />
          <span class="text" style="font-style: oblique; font-size: 14px"
            >Standard text rates may apply.</span
          > -->
        </div>
        <div class="form-item" v-if="showPhone">
          <van-field
            v-model="phone"
            type="tel"
            :placeholder="stringList.mobile_number"
            maxlength="12"
            @input="validateMobile"
          ></van-field>
        </div>
      </div>
    </div>
    <div class="bottom">
      <!-- <div class="btn" @click="createOrderBatch">
        <span>{{ stringList.submit }}</span>
      </div> -->
      <van-button @click="createOrderBatch" class="btn">{{
        stringList.submit
      }}</van-button>
    </div>
    <!--  日历控件  -->
    <van-calendar
      v-model="showDate"
      :color="primaryColor"
      :show-title="false"
      :min-date="getMinDate()"
      @confirm="dateConfirm"
      :default-date="getDefaultDate()"
    />

    <!-- 时间选择 -->
    <van-popup v-model="showTime" position="bottom">
      <van-picker
        :columns="timeColumns"
        show-toolbar
        @confirm="timeConfirm"
        @cancel="showTime = false"
      />
    </van-popup>

    <!-- 弹窗 -->
    <van-popup
      v-model="popShow"
      round
      position="bottom"
      :close-on-click-overlay="true"
      :get-container="() => $refs.amenities"
    >
      <!-- 一级 -->
      <div class="pop" v-if="firstPop" ref="popContainer">
        <div class="pop-title">
          <span
            class="title"
            :class="{ miniFont: language == 'es', miniFont2: language == 'tl' }"
          >
            {{ stringList.common_request_list }}
          </span>
          <div class="shopping">
            <van-badge :content="allNum">
              <img src="../assets/img/购物车.png" style="width: 27px" />
            </van-badge>
          </div>
        </div>
        <div class="pop-container">
          <div
            class="pop-item"
            v-for="item in firstPopData"
            :key="item.title"
            @click="goSecondPop(item)"
          >
            <img :src="require(`../assets/img/${item.icon}.png`)" />
            <span class="text" v-if="language == 'en'">{{ item.title }}</span>
            <span
              class="text"
              v-else
              :class="{ right_text_item: language == 'ar' }"
              >{{ item[language] }}</span
            >
            <a-icon class="icon" type="right" />
          </div>
          <div class="pop-item" v-if="hotelName != 'zylec'">
            <img src="../assets/img/other.png" alt="" />
            <span class="text" :class="{ right_text_item: language == 'ar' }">{{
              stringList.other_request
            }}</span>
          </div>
          <!-- :getPopupContainer="() => $refs.amenities" -->

          <div class="other-select" v-if="hotelName != 'zylec'">
            <a-select
              dropdownClassName="otherSelect"
              id="otherSelect"
              show-search
              size="large"
              class="select-other"
              :placeholder="stringList.select_item"
              :getPopupContainer="() => $refs.popContainer"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleSearch"
              @change="handleChange"
              :value="selectOtherRequest.keyword"
              @focus="clearSelectOtherRequest"
              :notFoundContent="stringList.noData"
            >
              <a-select-option
                v-for="item in otherRequestList"
                :key="item.id"
                :value="item.keyword"
              >
                <span v-if="language == 'en'">{{ item.keyword }}</span>
                <span v-else>{{ item[language] }}</span>
              </a-select-option>
            </a-select>
            <van-stepper
              default-value="0"
              v-model="selectOtherRequest.num"
              min="0"
              @change="numChange($event, selectOtherRequest.id)"
              theme="round"
              button-size="18"
              disable-input
            />
          </div>
        </div>
        <div class="btn-area">
          <!-- <div class="btn" @click="addRequest">
            <span>{{ stringList.add }}</span>
          </div> -->
          <van-button @click="addRequest" class="btn">{{
            stringList.add
          }}</van-button>
        </div>
      </div>
      <!-- 二级 -->
      <div class="pop" v-if="secondPop">
        <div class="pop-title">
          <div class="icon" @click="goFirstPop"><a-icon type="left" /></div>
          <span class="title" v-if="language == 'en'">
            {{ selectTitle.title }}
          </span>
          <span class="title" v-else>
            {{ selectTitle[language] }}
          </span>
          <div class="shopping">
            <van-badge :content="allNum">
              <img src="../assets/img/购物车.png" style="width: 27px" />
            </van-badge>
          </div>
        </div>
        <div class="pop-container">
          <template v-for="item in requestList">
            <div
              class="pop-item"
              :key="item.id"
              v-if="item.qrDisplayGroup && item.qrDisplayGroup == selectGroup"
            >
              <span class="text" v-if="language == 'en'">{{
                item.keyword
              }}</span>
              <span
                class="text"
                v-else
                :class="{ right_text_item: language == 'ar' }"
                >{{ item[language] }}</span
              >
              <van-stepper
                @change="numChange($event, item.id)"
                v-model="item.num"
                min="0"
                :default-value="0"
                theme="round"
                button-size="18"
                disable-input
              />
            </div>
          </template>
        </div>
        <div class="btn-area">
          <!-- <div class="btn" @click="addRequest">
            <span>{{ stringList.add }}</span>
          </div> -->
          <van-button @click="addRequest" class="btn">{{
            stringList.add
          }}</van-button>
        </div>
      </div>
    </van-popup>

    <!-- option1弹窗 -->
    <van-popup
      v-model="fdOption1"
      style="width: 300px"
      round
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div
          class="pop-title3"
          style="margin-bottom: 20px"
          :class="{ right_keywords_title: language == 'ar' }"
        >
          <span
            class="title"
            :class="{ right_keywords_title: language == 'ar' }"
            >{{ stringList.thank }}</span
          >
        </div>
        <div class="pop-info">
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ stringList.your_request }}
          </div>
          <div>
            <div
              class="info-item"
              style="font-weight: 600"
              :class="{ right_keywords_item: language == 'ar' }"
            >
              <span v-if="language == 'en'">{{ fdOptionRequest.keyword }}</span>
              <span v-else :class="{ right_text_item: language == 'ar' }">{{
                fdOptionRequest[language]
              }}</span>
            </div>
          </div>

          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ okContent }}
            <div v-if="okContent2 != ''" style="margin-top: 10px">
              {{ okContent2 }}
            </div>
          </div>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="() => $router.go(-1)">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="() => $router.go(-1)" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>

    <!-- option2弹窗 -->
    <van-popup
      v-model="fdOption2"
      round
      position="bottom"
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div class="pop-title" style="margin-bottom: 20px">
          <!-- <span class="title">{{ fdOptionRequest.keyword }}</span> -->
          <span
            class="title"
            :class="{ right_keywords_title: language == 'ar' }"
            >{{ stringList.thank }}</span
          >
        </div>
        <div class="pop-info">
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ stringList.your_request }}
            <span style="font-weight: 600" v-if="language == 'en'">{{
              fdOptionRequest.keyword
            }}</span>
            <span
              style="font-weight: 600"
              v-else
              :class="{ right_text_item: language == 'ar' }"
              >{{ fdOptionRequest[language] }}</span
            >
          </div>
          <div
            class="text"
            style="margin-bottom: 20px"
            :class="{ right_text_item: language == 'ar' }"
          >
            {{ fdOptionReponse }}
          </div>
          <van-radio-group
            v-model="pickUpOrDeliver"
            icon-size="16"
            direction="horizontal"
            style="margin-bottom: 10px"
          >
            <van-radio
              shape="square"
              name="comeToFD"
              style="margin-bottom: 10px"
              >{{ stringList.option1 }}</van-radio
            >
            <van-radio
              shape="square"
              name="deliver"
              style="margin-bottom: 10px"
              >{{ stringList.option2 }}</van-radio
            >
          </van-radio-group>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="pickUpOrDeliverOK">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="pickUpOrDeliverOK" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>

    <!-- 房间已有弹窗 -->
    <van-popup
      v-model="isHavedPop"
      round
      position="bottom"
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div class="pop-title" style="margin-bottom: 20px">
          <span
            class="title"
            :class="{ right_keywords_title: language == 'ar' }"
            >{{ stringList.confirmation }}</span
          >
        </div>
        <div class="pop-info" style="padding: 0 40px">
          <div
            class="text"
            style="font-size: 14px"
            :class="{ right_text_item: language == 'ar' }"
          >
            {{ isHavedRequest.response }}
          </div>
          <div
            class="text"
            style="margin-bottom: 20px; font-size: 14px"
            :class="{ right_text_item: language == 'ar' }"
          >
            {{ stringList.confirmationContent2 }}
          </div>
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <van-radio-group
              v-model="isHavedValue"
              icon-size="14"
              direction="horizontal"
              style="
                justify-content: space-between;
                width: 150px;
                margin-bottom: 10px;
              "
            >
              <van-radio shape="square" name="Yes">
                <span>{{ stringList.yes }}</span>
              </van-radio>
              <van-radio shape="square" name="No">
                <span>{{ stringList.no }}</span>
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="isHavedValueOK">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="isHavedValueOK" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>

    <!-- 需要自己去买弹窗answer -->
    <van-popup
      v-model="goShopSelfPop"
      style="width: 300px"
      round
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div
          class="pop-title3"
          style="margin-bottom: 20px"
          :class="{ right_keywords_title: language == 'ar' }"
        >
          <span
            class="title"
            :class="{ right_keywords_title: language == 'ar' }"
            >{{ stringList.thank }}</span
          >
        </div>
        <div class="pop-info">
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ stringList.your_request }}
          </div>
          <div
            class="info-item"
            style="margin-bottom: 10px; font-weight: 600"
            :class="{ right_keywords_item: language == 'ar' }"
          >
            <span v-if="language == 'en'">{{ goShopSelfRequest.keyword }}</span>
            <span v-else :class="{ right_text_item: language == 'ar' }">{{
              goShopSelfRequest[language]
            }}</span>
          </div>
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            <div>{{ okContent }}</div>
          </div>
          <template v-for="(file, index) in fileList">
            <div
              :key="index"
              v-if="showPdf(goShopSelfRequest.keyword, file)"
              @click="pdfView(file.fileUrl, file.title)"
              class="text"
              style="text-decoration: underline"
            >
              {{ JSON.parse(file.title2)[language] }}
            </div>
          </template>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="goShopSelfPopOK">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="goShopSelfPopOK" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>

    <!-- TransferToFD弹窗 -->
    <van-popup
      v-model="transferToFDPop"
      round
      position="bottom"
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div class="pop-title" style="margin-bottom: 20px">
          <span
            class="title"
            :class="{ right_keywords_title: language == 'ar' }"
            >{{ stringList.confirmation }}</span
          >
        </div>
        <div class="pop-info" style="padding: 0 40px">
          <div
            class="text"
            style="font-size: 14px"
            :class="{ right_text_item: language == 'ar' }"
          >
            {{ transferToFDRequest.response }}
          </div>
          <div
            class="text"
            style="margin-bottom: 20px; font-size: 14px"
            :class="{ right_text_item: language == 'ar' }"
          >
            <span>{{ stringList.confirmationContent }}</span>
          </div>
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <van-radio-group
              v-model="transferToFDValue"
              icon-size="14"
              direction="horizontal"
              style="
                justify-content: space-between;
                width: 150px;
                margin-bottom: 10px;
              "
            >
              <van-radio shape="square" name="Yes">
                <span>{{ stringList.yes }}</span></van-radio
              >
              <van-radio shape="square" name="No">
                <span>{{ stringList.no }}</span></van-radio
              >
            </van-radio-group>
          </div>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="transferToFDPopOK">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="transferToFDPopOK" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>

    <!-- TransferToFD或者TransferToRS yes分支弹窗 -->
    <van-popup
      v-model="thankPop2"
      style="width: 300px"
      round
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div
          class="pop-title3"
          style="margin-bottom: 20px"
          :class="{ right_keywords_title: language == 'ar' }"
        >
          <span
            class="title"
            :class="{ right_keywords_title: language == 'ar' }"
            >{{ stringList.thank }}</span
          >
        </div>
        <div class="pop-info">
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ stringList.responseContent1 }}
          </div>
          <div>
            <template v-for="item in requestList">
              <div
                class="info-item"
                v-if="item.num > 0"
                :key="item.id"
                style="font-weight: 600"
                :class="{ right_keywords_item: language == 'ar' }"
              >
                <span
                  >({{ item.num }})
                  <span v-if="language == 'en'">{{ item.keyword }}</span>
                  <span v-else :class="{ right_text_item: language == 'ar' }">{{
                    item[language]
                  }}</span>
                </span>
              </div>
            </template>
          </div>
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ stringList.responseContent3 }}
          </div>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="() => $router.go(-1)">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="() => $router.go(-1)" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>

    <!-- submit弹窗 -->
    <van-popup
      v-model="thankPop"
      style="width: 300px"
      round
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div
          class="pop-title3"
          style="margin-bottom: 20px"
          :class="{ right_keywords_title: language == 'ar' }"
        >
          <span
            class="title"
            :class="{ right_keywords_title: language == 'ar' }"
            >{{ stringList.thank }}</span
          >
        </div>
        <div class="pop-info">
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ stringList.responseContent1 }}
          </div>
          <div>
            <template v-for="item in requestList">
              <div
                class="info-item"
                v-if="item.num > 0"
                :key="item.id"
                style="font-weight: 600"
                :class="{ right_keywords_item: language == 'ar' }"
              >
                <span
                  >({{ item.num }})
                  <span v-if="language == 'en'">{{ item.keyword }}</span>
                  <span v-else :class="{ right_text_item: language == 'ar' }">{{
                    item[language]
                  }}</span></span
                >
              </div>
            </template>
          </div>
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ tips }}
          </div>
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ stringList.responseContent2 }}
          </div>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="() => $router.go(-1)">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="() => $router.go(-1)" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import moment from "moment-timezone";
import { postAction, getAction } from "@/api/manage";
import { Toast } from "vant";
import { encryptedDES } from "@/utils/DES.js";
import { message } from "ant-design-vue";

export default {
  metaInfo: {
    meta: [
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no",
      },
    ],
  },
  name: "Amenity",
  data() {
    return {
      popShow: true,
      firstPop: true,
      secondPop: false,
      fdOption1: false,
      fdOption2: false,
      requestList: [],
      otherRequestList: [],
      firstPopData: [
        {
          icon: "towels",
          title: "Towels",
          isSeleted: false, //弹过一次询问窗口
          pickUpOrDeliver: "comeToFD", //1去拿2送到房间
          es: "Toallas",
          tl: "Tuwalya",
          de: "Handtücher",
          zh: "毛巾",
          ar: "مناشف",
        },
        {
          icon: "coffee",
          title: "Coffee and Cups",
          isSeleted: false,
          pickUpOrDeliver: "comeToFD",
          es: "Café y tazas",
          tl: "Kape at Tasa",
          de: "Kaffee und Tassen",
          zh: "咖啡和杯子",
          ar: "قهوة وأكواب",
        },
        {
          icon: "bedding",
          title: "Bedding and Pillows",
          isSeleted: false,
          pickUpOrDeliver: "comeToFD",
          es: "Ropa de cama y almohadas",
          tl: "Sapin at Unan",
          de: "Bettwäsche und Kissen",
          zh: "床上用品和枕头",
          ar: "مفروشات ووسائد",
        },
        {
          icon: "bathroom",
          title: "Bathroom Amenities",
          isSeleted: false,
          pickUpOrDeliver: "comeToFD",
          es: "Artículos de baño",
          tl: "Mga Kagamitan sa Banyo",
          de: "Badezimmerausstattung",
          zh: "浴室用品",
          ar: "مستلزمات الحمام",
        },
        {
          icon: "dental",
          title: "Dental Kit",
          isSeleted: false,
          pickUpOrDeliver: "comeToFD",
          es: "Kit dental",
          tl: "Dental Kit",
          de: "Dental-Kit",
          zh: "牙齿清洁套装",
          ar: "مجموعة الأسنان",
        },
      ],
      selectOtherRequest: {
        keyword: undefined,
        num: 0,
        id: "",
      },
      allNum: 0,
      selectGroup: null,
      selectTitle: null,
      overNightHours: Vue.ls.get("overNightHours"),

      //夜间模式判断流程
      fdOptionRequest: {},
      pickUpOrDeliver: "comeToFD", //1去拿2送到房间

      url: {
        createOrder: "/api/common/hotel/data/qrorder/create",
        multipleCreateOrder: "/api/common/hotel/data/qrorder/multiplecreate",
        idWorker: "/api/common/hotel/data/idWorker",
        login: "/api/common/user/hotel_dept_name/login",
        hotelQuery: "/api/sys_admin/hotel/query",
        hotelinfo: "/adminApi/hotelinfo/infobyhotel",
        getPageInfo: "/adminApi/file/page",
        queryRoomNum: "/api/sys_admin/hotel/room/query2/",
        getKeyWord: "/api/app/current_user/keywordsalldept/",
        getReplyList: "/adminApi/reply/listbyhotel",
        getIsInputPhone: "/adminApi/textconfig/list",
        getFileList: "/adminApi/file/filelist",
        getLanguageList: "/api/common/hotel/data/dict/by_key/languageQr",
        about: "/adminApi/test/qr/about1",
      },
      //防止多次提交
      submiting: false,
      okContent: "",
      okContent2: "",

      //房间已有流程
      isHavedRequest: {},
      isHavedPop: false,
      isHavedValue: "No",

      //需要自己去买流程
      goShopSelfPop: false,
      goShopSelfRequest: {},
      roomNumber: Vue.ls.get("room") ? Vue.ls.get("room") : undefined,
      notes: "",
      date: "",
      showDate: false,
      time: "",
      showTime: false,
      roomArray: [],
      phone: "",
      timeColumns: [],
      thankPop: false,
      tips: "",
      fdOptionReponse: Vue.ls.get("fdOptionReponse"),
      notFoundContent: "",
      fileList: Vue.ls.get("fileList"),

      //transferToFD流程
      transferToFDPop: false,
      transferToFDValue: "No",
      transferToFDRequest: {},
      thankPop2: false,
      language: Vue.ls.get("language"),
      stringList: {},
      hotelName: Vue.ls.get("inno"),
      fdOption: Vue.ls.get("fdOption"),
    };
  },
  watch: {
    amcniticsRequest() {
      if (this.requestList.length == 0) {
        let arr = this.amcniticsRequest.map((item) => {
          // if (item.section == "CustomerDelivery") {
          //   console.log(item);
          // }
          item.num = 0;
          item.isSeleted = false; //other类别是否弹过一次夜间模式询问窗口标记
          item.isHavedSelected = false; //CustomerDelivery类别是否弹过窗口标记
          item.isTransferToFDPopSelected = false; //transferToFDPop弹窗标记
          return item;
        });
        this.requestList = JSON.parse(JSON.stringify(arr));
      }
    },
    requestList: {
      handler: function (val, oldVal) {
        this.allNum = 0;
        for (let i = 0; i < this.requestList.length; i++) {
          const element = this.requestList[i];
          this.allNum += element.num;
        }
      },
      deep: true,
    },
    notes(n, o) {
      if (n.length > 199 && n.length != 0) {
        Toast({
          message:
            `<div style="word-break: normal;font-size:14px;width=300px"><span>` +
            this.stringList.limit_description +
            `</span></div>`,
          type: "html",
        });
        return;
      }
    },
  },
  computed: {
    ...mapGetters({
      amcniticsRequest: "amcniticsRequest", //Amcnitics的所有request
      roomList: "roomList",
      showPhone: "showPhone",
      primaryColor: "primaryColor",
      informationRequest: "informationRequest",
    }),
  },

  async created() {
    console.log(this.hotelName);
    //无痕浏览器直接进入逻辑
    let tsp = this.$route.query.tsp;
    let now = new Date().getTime();
    if (now - this.tsp * 1000 > 1000 * 60 * 60 * 24) {
      //如果是走了这个about 后台调用一下接口 打log
      getAction(this.url.about).then((res) => {});
      this.$router.push({ name: "About" });
    } else {
      if (!Vue.ls.get("HTTOKEN")) {
        let params = {
          hotelDeptName: "qrbot",
          hotelName: this.$route.query.inno,
          password: "123456",
        };
        postAction(this.url.login, params).then((res) => {
          if (res.status == 0) {
            this.token = res.data.token;
            Vue.ls.set("HTTOKEN", res.data.token);
            getAction(this.url.getLanguageList).then((res) => {
              Vue.ls.set("languageList", res.data);
            });
            postAction(this.url.hotelQuery, {
              hotelNo: this.$route.query.inno,
            }).then((res) => {
              if (res.status == 0) {
                Vue.ls.set("timeZone", res.data.timeZone, 1000 * 60 * 60 * 24);
                Vue.ls.set("hotelId", res.data.id, 1000 * 60 * 60 * 24);
                //判断是否启动夜间模式
                Vue.ls.set(
                  "overNightHoursInfo",
                  res.data.overNightHours,
                  1000 * 60 * 60 * 24
                );
                // if (
                //   res.data.overNightHours != "disable" &&
                //   res.data.overNightHours != "Regular Operating Hour"
                // ) {
                //   Vue.ls.set("overNightHours", true, 1000 * 60 * 60 * 24);
                // } else {
                //   Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                // }
                if (
                  res.data.overNightHours == "disable" ||
                  res.data.overNightHours == "Regular Operating Hour"
                ) {
                  Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                } else if (
                  res.data.overNightHours == "Cycle Regular Operating Hour"
                ) {
                  // Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                  let now = new Date();
                  let taody = moment(now)
                    .tz(res.data.timeZone)
                    .format("YYYY/MM/DD");
                  let startTime = moment.tz(
                    moment(taody + " " + res.data.fdStartTime).format(
                      "YYYY-MM-DD HH:mm"
                    ),
                    res.data.timeZone
                  );
                  let endTime = moment.tz(
                    moment(
                      res.data.fdEndTime.replace("tomorrow_", taody + " ")
                    ).format("YYYY-MM-DD HH:mm"),
                    res.data.timeZone
                  );
                  if (this.getIsInTimeRange(startTime, endTime)) {
                    Vue.ls.set("overNightHours", true, 1000 * 60 * 60 * 24);
                  } else {
                    Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                  }
                } else {
                  Vue.ls.set("overNightHours", true, 1000 * 60 * 60 * 24);
                }
                Vue.ls.set(
                  "fdStartTime",
                  res.data.fdStartTime,
                  1000 * 60 * 60 * 24
                );
                Vue.ls.set(
                  "fdEndTime",
                  res.data.fdEndTime,
                  1000 * 60 * 60 * 24
                );

                Vue.ls.set("engHour", res.data.engHour, 1000 * 60 * 60 * 24);
                Vue.ls.set(
                  "engEndTime",
                  res.data.engEndTime,
                  1000 * 60 * 60 * 24
                );
                Vue.ls.set(
                  "engStartTime",
                  res.data.engStartTime,
                  1000 * 60 * 60 * 24
                );
                Vue.ls.set(
                  "engRequestTime",
                  res.data.engRequestTime,
                  1000 * 60 * 60 * 24
                );
                getAction(this.url.hotelinfo, { hotelId: res.data.id }).then(
                  (r) => {
                    if (r.status == 0) {
                      this.editColor("#" + r.data.color);
                    }
                  }
                );
                postAction(this.url.getFileList, { hotelId: res.data.id }).then(
                  (r) => {
                    if (r.status == 0) {
                      Vue.ls.set(
                        "fileList",
                        r.data.htFileList,
                        1000 * 60 * 60 * 24
                      );
                    }
                  }
                );
                let params = {
                  hotelId: Vue.ls.get("hotelId"),
                  type: "internal",
                };
                getAction(this.url.getReplyList, params).then((res) => {
                  if (res.status == 0) {
                    for (let element of res.data) {
                      if (
                        element.botTrigger == "556" ||
                        element.botTrigger == "557"
                      ) {
                        Vue.ls.set(
                          "fdOption",
                          element.botTriggerValue,
                          1000 * 60 * 60 * 24
                        );
                        Vue.ls.set(
                          "fdOptionReponse",
                          element.botResponse2,
                          1000 * 60 * 60 * 24
                        );
                        break;
                      }
                    }
                    for (let element of res.data) {
                      if (element.botTrigger == "677") {
                        Vue.ls.set(
                          "engHourReponse",
                          element.botResponse2,
                          1000 * 60 * 60 * 24
                        );
                        break;
                      }
                    }
                  }
                });
                let form = {
                  hotelId: Vue.ls.get("hotelId"),
                  size: 10,
                  current: 1,
                };
                postAction(this.url.getIsInputPhone, form).then((res) => {
                  if (res.status == 0) {
                    res.data.records.forEach((item) => {
                      if (Vue.ls.get("type") == "guest") {
                        if (item.requestType == "Guest Room Request") {
                          if (item.textResponse == "Enable") {
                            this.editShowPhone(true);
                            return;
                          }
                        }
                      } else if (Vue.ls.get("type") == "nonguest") {
                        if (item.requestType == "Meeting Room Request") {
                          if (item.textResponse == "Enable") {
                            this.editShowPhone(true);
                            return;
                          }
                        }
                      }
                    });
                  }
                });
              }
            });
            if (this.amcniticsRequest.length == 0) {
              getAction(`${this.url.getKeyWord}${"fdkey"}`).then((res) => {
                if (res.status == 0) {
                  this.editAmcniticsRequest(res.data);
                }
              });
            }
            if (this.informationRequest.length == 0) {
              getAction(`${this.url.getKeyWord}${"nonfdkey"}`).then((res) => {
                if (res.status == 0) {
                  this.editInformationRequest(res.data);
                }
              });
            }
            if (this.$route.query.type) {
              Vue.ls.set("type", this.$route.query.type, 1000 * 60 * 60 * 24);
              if (Vue.ls.get("type") == "guest") {
                getAction(
                  `${this.url.queryRoomNum}${"guest"}/${"allmeeting"}`
                ).then((res) => {
                  if (res.status == 0) {
                    this.editRoomList(res.data);
                  }
                });
              } else if (Vue.ls.get("type") == "nonguest") {
                getAction(
                  `${
                    this.url.queryRoomNum
                  }${"guMeeting Roomest"}/${"allmeeting"}`
                ).then((res) => {
                  if (res.status == 0) {
                    this.editRoomList(res.data);
                  }
                });
              }
            }
            if (this.$route.query.room) {
              Vue.ls.set("room", this.$route.query.room, 1000 * 60 * 60 * 24);
            }
            if (this.$route.query.inno) {
              Vue.ls.set("inno", this.$route.query.inno, 1000 * 60 * 60 * 24);
            }
          }
        });
        if (!Vue.ls.get("language")) {
          Vue.ls.set("language", "en");
          this.language = "en";
        }
        if (this.hotelName == "zylec") {
          this.firstPopData = [
            {
              icon: "towels",
              title: "Towels",
              isSeleted: false, //弹过一次询问窗口
              pickUpOrDeliver: "comeToFD", //1去拿2送到房间
              es: "Toallas",
              tl: "Tuwalya",
              de: "Handtücher",
              zh: "毛巾",
              ar: "مناشف",
            },
            {
              icon: "bedding",
              title: "Bedding and Pillows",
              isSeleted: false,
              pickUpOrDeliver: "comeToFD",
              es: "Ropa de cama y almohadas",
              tl: "Sapin at Unan",
              de: "Bettwäsche und Kissen",
              zh: "床上用品和枕头",
              ar: "مفروشات ووسائد",
            },
          ];
        }
        let arr = this.amcniticsRequest.map((item) => {
          item.num = 0;
          item.isSeleted = false; //other类别是否弹过一次夜间模式询问窗口标记
          item.isHavedSelected = false; //CustomerDelivery类别是否弹过窗口标记
          item.isTransferToFDPopSelected = false; //transferToFDPop弹窗标记
          if (item.keyword == "Pillow") {
            console.log(item.id);
          }
          return item;
        });
        this.requestList = JSON.parse(JSON.stringify(arr));

        // 初始化时间控件
        let hour = [];
        for (let i = 1; i <= 12; i++) {
          if (i < 10) {
            hour.push("0" + i);
          } else {
            hour.push("" + i);
          }
        }
        this.timeColumns.push({
          values: hour,
        });
        let minte = [];
        for (let i = 0; i <= 59; i++) {
          if (i < 10) {
            minte.push("0" + i);
          } else {
            minte.push("" + i);
          }
        }
        this.timeColumns.push({
          values: minte,
        });
        this.timeColumns.push({
          values: ["AM", "PM"],
        });
        let languageJsonName = "String_" + Vue.ls.get("language") + ".json";
        this.stringList = await import("@/data/" + languageJsonName);
        this.fdOptionReponse = JSON.parse(this.fdOptionReponse)[this.language];
      } else {
        if (this.hotelName == "zylec") {
          this.firstPopData = [
            {
              icon: "towels",
              title: "Towels",
              isSeleted: false, //弹过一次询问窗口
              pickUpOrDeliver: "comeToFD", //1去拿2送到房间
              es: "Toallas",
              tl: "Tuwalya",
              de: "Handtücher",
              zh: "毛巾",
              ar: "مناشف",
            },
            {
              icon: "bedding",
              title: "Bedding and Pillows",
              isSeleted: false,
              pickUpOrDeliver: "comeToFD",
              es: "Ropa de cama y almohadas",
              tl: "Sapin at Unan",
              de: "Bettwäsche und Kissen",
              zh: "床上用品和枕头",
              ar: "مفروشات ووسائد",
            },
          ];
        }
        let arr = this.amcniticsRequest.map((item) => {
          item.num = 0;
          item.isSeleted = false; //other类别是否弹过一次夜间模式询问窗口标记
          item.isHavedSelected = false; //CustomerDelivery类别是否弹过窗口标记
          item.isTransferToFDPopSelected = false; //transferToFDPop弹窗标记
          if (item.keyword == "Pillow") {
            console.log(item.id);
          }
          return item;
        });
        this.requestList = JSON.parse(JSON.stringify(arr));

        // 初始化时间控件
        let hour = [];
        for (let i = 1; i <= 12; i++) {
          if (i < 10) {
            hour.push("0" + i);
          } else {
            hour.push("" + i);
          }
        }
        this.timeColumns.push({
          values: hour,
        });
        let minte = [];
        for (let i = 0; i <= 59; i++) {
          if (i < 10) {
            minte.push("0" + i);
          } else {
            minte.push("" + i);
          }
        }
        this.timeColumns.push({
          values: minte,
        });
        this.timeColumns.push({
          values: ["AM", "PM"],
        });
        let languageJsonName = "String_" + Vue.ls.get("language") + ".json";
        this.stringList = await import("@/data/" + languageJsonName);
        this.fdOptionReponse = JSON.parse(this.fdOptionReponse)[this.language];
      }
    }
  },
  methods: {
    moment,
    ...mapActions([
      "editColor",
      "editAmcniticsRequest",
      "editInformationRequest",
      "editShowPhone",
      "editRoomList",
    ]),
    showAddMore() {
      let arr = this.requestList.filter((item) => {
        return item.num > 0;
      });
      if (arr.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    pdfView(inno, title) {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      let urlType = inno.split(".")[inno.split(".").length - 1];
      //创建订单
      let now = new Date();
      let createTime = moment(now).tz(Vue.ls.get("timeZone"));
      let time = new Date().valueOf().toString();
      let params = {
        createTime: createTime.utc().format(),
        fdaction: "",
        fdhour: Vue.ls.get("overNightHoursInfo"),
        hotelId: Vue.ls.get("hotelId"),
        hotelDeptId: "",
        inquiryNum: "",
        notes: "",
        requestType: Vue.ls.get("type"),
        roomNumber: Vue.ls.get("room") ? Vue.ls.get("room") : "GSD",
        requestTime: createTime.utc().format(),
        serviceKey: title ? title : "Guest Service Directory",
        serviceType: "gsd",
        createTimeValue: time,
        tid: encryptedDES(time.substring(time.length - 8)),
        language: this.language,
      };

      if (!this.submiting) {
        this.submiting = true;
        postAction(this.url.createOrder, params)
          .then((res) => {
            if (res.success) {
              this.submiting = false;
            }
          })
          .catch((e) => {
            this.submiting = false;
          });
      }

      if (
        urlType == "xlsx" ||
        urlType == "docx" ||
        urlType == "pptx" ||
        urlType == "xls" ||
        urlType == "doc" ||
        urlType == "ppt"
      ) {
        window.location.href =
          "https://view.officeapps.live.com/op/view.aspx?src=" + inno;
      } else if (
        urlType == "jpg" ||
        urlType == "png" ||
        urlType == "jpeg" ||
        urlType == "gif"
      ) {
        window.location.href = inno;
      } else if (urlType == "pdf") {
        this.$router.push({
          name: "PdfView",
          query: { inno: inno },
        });
      } else {
        window.location.href = inno;
      }
    },
    showPdf(keyword, file) {
      if (!keyword) return false;
      let services = file.service;
      for (let ss of services.split(",")) {
        if (
          keyword.toLowerCase() == ss.toLowerCase() &&
          file.enable != null &&
          file.enable
        ) {
          return true;
        }
      }
      return false;
    },
    clearSelectOtherRequest() {
      // let screenHeight = window.innerHeight;
      //  let keyboardHeight = 0
      //  let time = 0;
      //  // 获取键盘高度
      //  let interval  = setInterval(() => {
      //     if (screenHeight !== window.innerHeight) {
      //           keyboardHeight = screenHeight-window.innerHeight;
      //           clearInterval(interval)
      //        }
      //   }, 50);

      // // 将输入框复原到原位
      // let timer = setInterval(() => {
      //   time++;
      //   if(time <=5) {
      //      if(keyboardHeight) {
      //         document.documentElement.scrollTop = keyboardHeight + 110
      //       }
      //    } else {
      //      clearInterval(timer)
      //    }
      //  }, 50);

      document.getElementById("otherSelect").scrollIntoView();
      this.selectOtherRequest = {
        keyword: undefined,
        id: "",
        num: 0,
      };
      this.otherRequestList = this.requestList;
    },
    clearRoomNumber() {
      if (this.roomNumber == undefined || this.roomNumber == "") {
        this.roomArray = this.roomList;
      } else {
        this.roomNumber = "";
        this.roomArray = [];
        this.notFoundContent = "";
        this.roomArray = this.roomList;
      }
    },
    changeNum($event, id) {
      this.$forceUpdate();
    },
    validateMobile(val) {
      //美式mobile 号码格式，3-3-4格式
      //代码参照https://github.com/wuhao000/antd-mobile-vue/blob/master/src/packages/input/src/index.tsx
      let ctrlValue = val;
      ctrlValue = val.replace(/\D/g, "").substring(0, 11);
      const valueLen = ctrlValue.length;
      if (valueLen > 3 && valueLen < 7) {
        ctrlValue = `${ctrlValue.substr(0, 3)}-${ctrlValue.substr(3)}`;
      } else if (valueLen >= 7) {
        ctrlValue = `${ctrlValue.substr(0, 3)}-${ctrlValue.substr(
          3,
          3
        )}-${ctrlValue.substr(6)}`;
      }
      this.phone = ctrlValue;
    },
    onClickLeft() {
      // this.$router.go(-1);
      let query = {};
      query.type = this.$route.query.type;
      query.inno = this.$route.query.inno;
      if (this.$route.query.room) {
        query.room = this.$route.query.room;
      }
      query.tsp = this.$route.query.tsp;
      this.$router.replace({ name: "Home", query: query });
    },
    goSecondPop(value) {
      this.firstPop = false;
      this.secondPop = true;
      this.selectGroup = value.title;
      this.selectTitle = value;
    },
    goFirstPop() {
      this.selectGroup = "";
      this.firstPop = true;
      this.secondPop = false;
      setTimeout(() => {
        console.log(document.getElementById("otherSelect"));
        document.getElementById("otherSelect").focus();
      }, 200);
    },
    roomSearch(value) {
      if (value) {
        this.roomArray = this.roomList.filter((item) => {
            return item.name.toLowerCase().indexOf(value.toLowerCase()) != -1;
        });
        this.notFoundContent = this.stringList.invalid_room;
      } else {
        this.roomArray = [];
        this.notFoundContent = "";
      }
      if (this.roomArray.length == 1) {
        this.roomNumber = this.roomArray[0].name;
        document.querySelector(":focus").blur();
      }
    },
    roomChange(value) {
      this.roomNumber = value;
      this.roomArray = this.roomList.filter((item) => {
        return item.name.indexOf(value) != -1;
      });
      if (this.roomArray.length == 1) {
        this.roomNumber = this.roomArray[0].name;
        document.querySelector(":focus").blur();
      }
    },
    handleSearch(value) {
      if (this.language == "en") {
        this.otherRequestList = this.requestList.filter((item) => {
          return item.keyword.toLowerCase().indexOf(value.toLowerCase()) != -1;
        });
      } else {
        this.otherRequestList = this.requestList.filter((item) => {
          if (item[this.language]) {
            return (
              item[this.language].toLowerCase().indexOf(value.toLowerCase()) !=
              -1
            );
          }
        });
      }
    },
    handleChange(value) {
      this.selectOtherRequest.keyword = value;
      this.otherRequestList = this.requestList.filter((item) => {
        return item.keyword.toLowerCase().indexOf(value.toLowerCase()) != -1;
      });
      for (let i = 0; i < this.otherRequestList.length; i++) {
        let item = this.otherRequestList[i];
        // if (item.keyword.indexOf(value) != -1) {
        //   this.selectOtherRequest.id = item.id;
        //   this.selectOtherRequest.num = item.num;
        //   break;
        // }
        if (item.keyword == value) {
          this.selectOtherRequest.id = item.id;
          this.selectOtherRequest.num = item.num;
          break;
        }
      }
    },
    getIsInTimeRange(startTime, endTime) {
      let now = new Date();
      if (startTime.valueOf() < endTime.valueOf()) {
        return (
          startTime.valueOf() <= now.getTime() &&
          now.getTime() <= endTime.valueOf()
        );
      } else {
        return (
          startTime.valueOf() <= now.getTime() ||
          now.getTime() <= endTime.valueOf()
        );
      }
    },
    numChange(num, id) {
      // console.log(num);
      if (!id) {
        return;
      }
      console.log(id);
      // console.log(this.overNightHours);
      //先判断是否在房间内CustomerDelivery
      this.requestList.map((item) => {
        if (item.id == id) {
          this.$set(item, "num", num);
        }
        return item;
      });
      if (num == 0) {
        return;
      }
      let request = this.requestList.find((item) => {
        return item.id == id;
      });
      console.log(request);
      if (
        (request.section == "CustomerDelivery" &&
          this.fdOption != "Front Desk Hour Option1") ||
        (request.section == "CustomerDelivery" &&
          this.fdOption == "Front Desk Hour Option1" &&
          !this.overNightHours)
      ) {
        if (!request.isHavedSelected) {
          this.isHavedRequest = request;
          if (request.response) {
            this.isHavedRequest.response = JSON.parse(request.response)[
              this.language
            ];
          }
          this.popShow = false;
          this.isHavedPop = true;
          this.isHavedValue = "No";
          request.isHavedSelected = true;
        }
      } else if (
        request.section == "CustomerDelivery" &&
        this.fdOption == "Front Desk Hour Option1"
      ) {
        this.fdOptionRequest = request;
        this.createClosedTicket(this.fdOptionRequest);
      }
      //判断是否要自己去买Answer
      else if (request.section == "Answer") {
        this.goShopSelfRequest = request;
        this.popShow = false;
        this.createClosedTicket(request);
      } else if (
        (request.section == "TransferToFD" ||
          request.section == "TransferToRS") &&
        this.overNightHours &&
        this.fdOption == "Front Desk Hour Option1"
      ) {
        this.goShopSelfRequest = request;
        this.popShow = false;
        this.createClosedTicket(request);
      } else if (
        request.section == "TransferToFD" ||
        request.section == "TransferToRS"
      ) {
        if (!request.isTransferToFDPopSelected) {
          this.transferToFDRequest = request;
          if (request.response) {
            this.transferToFDRequest.response = JSON.parse(request.response)[
              this.language
            ];
          }
          this.popShow = false;
          this.transferToFDValue = "No";
          this.transferToFDPop = true;
          request.isTransferToFDPopSelected = true;
        }
      } else {
        if (this.overNightHours) {
          let now = new Date();
          let taody = moment(now)
            .tz(Vue.ls.get("timeZone"))
            .format("YYYY/MM/DD");
          let startTime = moment.tz(
            moment(taody + " " + Vue.ls.get("fdStartTime")).format(
              "YYYY-MM-DD HH:mm"
            ),
            Vue.ls.get("timeZone")
          );
          let endTime = moment.tz(
            moment(
              Vue.ls.get("fdEndTime").replace("tomorrow_", taody + " ")
            ).format("YYYY-MM-DD HH:mm"),
            Vue.ls.get("timeZone")
          );

          //处于夜间模式
          if (this.getIsInTimeRange(startTime, endTime)) {
            this.fdOptionRequest = request;

            if (Vue.ls.get("fdOption") == "Front Desk Hour Option1") {
              this.popShow = false;
              this.createClosedTicket(this.fdOptionRequest);
            } else {
              //判断是否弹窗
              if (this.secondPop) {
                let target = this.firstPopData.find((item) => {
                  return item.title == this.selectGroup;
                });
                if (target && target.isSeleted) {
                  //如果target.pickUpOrDeliver=comeToFD去拿 =deliver送到房间来
                  if (target.pickUpOrDeliver == "comeToFD") {
                    this.createClosedTicket(this.fdOptionRequest);
                  }
                } else {
                  this.popShow = false;
                  this.fdOption2 = true;
                  this.pickUpOrDeliver = "comeToFD";
                }
              } else {
                //如果是other类别
                if (!request.isSeleted) {
                  this.popShow = false;
                  this.fdOption2 = true;
                  this.pickUpOrDeliver = "comeToFD";
                  request.isSeleted = true;
                }
              }
            }
          }
        }
      }
    },
    addRequest() {
      if (this.allNum > 0) {
        document.getElementById("roomNumber").scrollIntoView();
        this.amcniticsRequest;
        this.popShow = false;
        this.firstPop = true;
        this.secondPop = false;
        this.selectGroup = "";
      }
    },
    addMore() {
      this.popShow = true;
    },
    pickUpOrDeliverOK() {
      this.firstPopData = this.firstPopData.map((item) => {
        if (item.title == this.selectGroup) {
          item.isSeleted = true;
          item.pickUpOrDeliver = this.pickUpOrDeliver;
        }
        return item;
      });
      if (this.pickUpOrDeliver == "deliver") {
        if (this.selectGroup) {
          this.fdOption2 = false;
          this.popShow = true;
          this.firstPop = false;
          this.secondPop = true;
        } else {
          this.fdOption2 = false;
          this.popShow = true;
          this.firstPop = true;
          this.secondPop = false;
        }
      } else {
        this.fdOption2 = false;
        this.createClosedTicket(this.fdOptionRequest);
      }
    },
    isHavedValueOK() {
      if (this.isHavedValue == "Yes") {
        if (this.selectGroup) {
          this.isHavedPop = false;
          this.popShow = true;
          this.firstPop = false;
          this.secondPop = true;
        } else {
          this.isHavedPop = false;
          this.popShow = true;
          this.firstPop = true;
          this.secondPop = false;
        }
      } else {
        this.createClosedTicket(this.isHavedRequest);
      }
    },
    goShopSelfPopOK() {
      if (this.allNum > 0) {
        if (this.selectGroup) {
          this.goShopSelfPop = false;
          this.popShow = true;
          this.firstPop = false;
          this.secondPop = true;
        } else {
          this.goShopSelfPop = false;
          this.popShow = true;
          this.firstPop = true;
          this.secondPop = false;
        }
      } else {
        // this.$router.go(-1);
        let query = {};
        query.type = this.$route.query.type;
        query.inno = this.$route.query.inno;
        if (this.$route.query.room) {
          query.room = this.$route.query.room;
        }
        query.tsp = this.$route.query.tsp;
        this.$router.replace({ name: "Home", query: query });
      }
    },
    transferToFDPopOK() {
      if (this.transferToFDValue == "No") {
        this.createClosedTicket(this.transferToFDRequest);
      } else {
        if (this.selectGroup) {
          this.transferToFDPop = false;
          this.popShow = true;
          this.firstPop = false;
          this.secondPop = true;
        } else {
          this.transferToFDPop = false;
          this.popShow = true;
          this.firstPop = true;
          this.secondPop = false;
        }
      }
    },
    createClosedTicket(request) {
      let now = new Date();
      let createTime = moment(now).tz(Vue.ls.get("timeZone"));
      let fdaction = "";
      if (request.section == "CustomerDelivery") {
        fdaction = "No";
      } else if (request.section == "Answer") {
        fdaction = "";
      } else if (
        (request.section == "TransferToFD" ||
          request.section == "TransferToRS") &&
        this.overNightHours
      ) {
        fdaction = "comeToFD";
      } else if (
        request.section == "TransferToFD" ||
        request.section == "TransferToRS"
      ) {
        fdaction = "No";
      } else {
        fdaction = "comeToFD";
      }
      let time = new Date().valueOf().toString();
      let params = {
        hotelId: Vue.ls.get("hotelId"),
        createTime: createTime.utc().format(),
        fdaction: fdaction,
        fdhour: Vue.ls.get("overNightHoursInfo"),
        hotelDeptId: request.hotelDeptId,
        inquiryNum: 1,
        notes: "",
        requestType: Vue.ls.get("type"),
        roomNumber: "None",
        requestTime: createTime.utc().format(),
        serviceKey: request.keyword,
        serviceType: request.serviceType,
        phone: null,
        status: "Complete-Bot",
        createTimeValue: time,
        tid: encryptedDES(time.substring(time.length - 8)),
        language: this.language,
      };
      if (
        Vue.ls.get("overNightHoursInfo") == "Front Desk Hour" ||
        Vue.ls.get("overNightHoursInfo") == "Cycle Front Desk Hour"
      ) {
        params.fdhour = Vue.ls.get("fdOption");
      } else if (
        Vue.ls.get("overNightHoursInfo") == "Cycle Regular Operating Hour"
      ) {
        params.fdhour = "Regular Operating Hour";
      }
      if (!this.submiting) {
        this.submiting = true;
        postAction(this.url.createOrder, params)
          .then((res) => {
            if (res.success) {
              this.submiting = false;
              this.requestList.map((item) => {
                if (item.id == request.id) {
                  item.num = 0;
                }
                return item;
              });
              this.selectOtherRequest = {
                keyword: undefined,
                num: 0,
              };
              if (
                (request.section == "CustomerDelivery" &&
                  this.fdOption != "Front Desk Hour Option1") ||
                (request.section == "CustomerDelivery" &&
                  this.fdOption == "Front Desk Hour Option1" &&
                  !this.overNightHours)
              ) {
                // console.log(this.allNum);
                if (this.allNum > 1) {
                  this.isHavedPop = false;
                  this.popShow = true;
                  this.firstPop = true;
                  this.secondPop = false;
                } else {
                  // this.$router.go(-1);
                  let query = {};
                  query.type = this.$route.query.type;
                  query.inno = this.$route.query.inno;
                  if (this.$route.query.room) {
                    query.room = this.$route.query.room;
                  }
                  query.tsp = this.$route.query.tsp;
                  this.$router.replace({ name: "Home", query: query });
                }
              } else if (
                request.section == "CustomerDelivery" &&
                this.fdOption == "Front Desk Hour Option1"
              ) {
                this.fdOption1 = true;
                this.okContent2 =
                  this.stringList.need_one + " " + this.fdOptionReponse;
                this.okContent = res.msg;
              } else if (request.section == "Answer") {
                this.goShopSelfPop = true;
                this.okContent = res.msg;
              } else if (
                (request.section == "TransferToFD" ||
                  request.section == "TransferToRS") &&
                this.overNightHours
              ) {
                this.goShopSelfPop = true;
                this.okContent = res.msg;
              } else if (
                request.section == "TransferToFD" ||
                request.section == "TransferToRS"
              ) {
                if (this.allNum > 1) {
                  if (this.selectGroup) {
                    this.transferToFDPop = false;
                    this.popShow = true;
                    this.firstPop = false;
                    this.secondPop = true;
                  } else {
                    this.transferToFDPop = false;
                    this.popShow = true;
                    this.firstPop = true;
                    this.secondPop = false;
                  }
                } else {
                  // this.$router.go(-1);
                  let query = {};
                  query.type = this.$route.query.type;
                  query.inno = this.$route.query.inno;
                  if (this.$route.query.room) {
                    query.room = this.$route.query.room;
                  }
                  query.tsp = this.$route.query.tsp;
                  this.$router.replace({ name: "Home", query: query });
                }
              } else {
                if (Vue.ls.get("fdOption") == "Front Desk Hour Option1") {
                  this.fdOption1 = true;
                  this.okContent = res.msg;
                } else {
                  if (this.allNum > 1) {
                    this.fdOption2 = false;
                    this.popShow = true;
                    this.firstPop = true;
                    this.secondPop = false;
                  } else {
                    // this.$router.go(-1);
                    let query = {};
                    query.type = this.$route.query.type;
                    query.inno = this.$route.query.inno;
                    if (this.$route.query.room) {
                      query.room = this.$route.query.room;
                    }
                    query.tsp = this.$route.query.tsp;
                    this.$router.replace({ name: "Home", query: query });
                  }
                }
              }
            } else {
              this.submiting = false;
            }
          })
          .catch((e) => {
            this.submiting = false;
          });
      }
    },
    dateConfirm(date) {
      // console.log(moment(date.getTime()).format("MM/DD/YYYY"));
      this.date = moment(date.getTime()).format("MM/DD/YYYY");
      let nowDate = moment(new Date().getTime())
        .tz(Vue.ls.get("timeZone"))
        .format("YYYY/MM/DD");
      // 判断是否为今天
      if (
        this.date !=
        moment(new Date().getTime())
          .tz(Vue.ls.get("timeZone"))
          .format("MM/DD/YYYY")
      ) {
        this.time = "10:00 AM";
      } else {
        this.time = this.stringList.asap;
      }
      this.showDate = false;
    },
    getDefaultDate() {
      let date = moment(new Date())
        .tz(Vue.ls.get("timeZone"))
        .format("YYYY/MM/DD");
      // console.log(date);
      return new Date(date);
    },
    getMinDate() {
      let date = moment(new Date())
        .tz(Vue.ls.get("timeZone"))
        .format("YYYY-MM-DD");
      // console.log(date);
      return new Date(date);
    },
    timeConfirm(value) {
      this.showTime = false;
      this.timePickerData = value;
      this.time = value[0] + ":" + value[1] + " " + value[2];
    },
    selectTime() {
      this.showTime = true;
      let time = moment(new Date())
        .tz(Vue.ls.get("timeZone"))
        .format("hh:mm A");
      this.timeColumns[0].defaultIndex = Number(time.slice(0, 2)) - 1;
      this.timeColumns[1].defaultIndex = Number(time.slice(3, 5));
      this.timeColumns[2].defaultIndex =
        time.slice(time.length - 2, time.length) == "AM" ? 0 : 1;
    },

    async createOrderBatch() {
      document.getElementById("roomNumber").scrollIntoView();
      if (!this.roomNumber) {
        Toast(this.stringList.room_toast);
        return;
      }

      let arr = [];
      for (let i = 0; i < this.requestList.length; i++) {
        const request = this.requestList[i];
        if (request.num > 0) {
          arr.push(request);
        }
      }
      // console.log(arr);
      if (arr.length == 0) {
        Toast(this.stringList.service_toast);
        return;
      }
      if (this.phone != null && this.phone != "" && this.phone.length != 12) {
        Toast(this.stringList.phone_toast);
        return;
      }

      //请求orderId
      let orderId;
      await getAction(this.url.idWorker).then((res) => {
        orderId = res.data;
      });

      let now = new Date();
      let createTime = moment(now).tz(Vue.ls.get("timeZone"));

      //确定requestTime
      let today = createTime.format("MM/DD/YY");
      let tomorrow = moment(createTime.valueOf() + 1000 * 60 * 60 * 24)
        .tz(Vue.ls.get("timeZone"))
        .format("MM/DD/YY");

      let date = this.date;
      if (!date) {
        date = moment(new Date())
          .tz(Vue.ls.get("timeZone"))
          .format("YYYY/MM/DD");
      }
      let time = this.time;
      if (!time || time == this.stringList.asap) {
        time = moment(new Date()).tz(Vue.ls.get("timeZone")).format("hh:mm A");
      }
      let requestTime = moment.tz(
        moment(date + " " + time).format("YYYY-MM-DD HH:mm"),
        Vue.ls.get("timeZone")
      );

      if (requestTime.valueOf() + 180000 < now.getTime()) {
        Toast(this.stringList.time_toast);
        return;
      }

      if (!this.date && !this.time) {
        this.tips = this.stringList.tipsContent1;
      } else {
        if (requestTime.format("MM/DD/YY") == today) {
          this.tips =
            this.stringList.tipsContent2 + requestTime.format("hh:mm A") + ".";
        } else if (requestTime.format("MM/DD/YY") == tomorrow) {
          this.tips =
            this.stringList.tipsContent3 + requestTime.format("hh:mm A") + ".";
        } else {
          this.tips =
            this.stringList.tipsContent4 +
            requestTime.format("MM/DD/YY hh:mm A") +
            ".";
        }
      }

      // console.log(requestTime);
      let params = [];
      for (let i = 0; i < arr.length; i++) {
        const request = arr[i];
        let fdaction = "";
        if (request.section == "CustomerDelivery") {
          fdaction = "Yes";
        } else if (request.section == "Answer") {
          fdaction = "";
        } else if (
          request.section == "TransferToFD" ||
          request.section == "TransferToRS"
        ) {
          fdaction = "Yes";
        } else {
          fdaction = "deliver";
        }
        let time = new Date().valueOf().toString();
        let param = {
          hotelId: Vue.ls.get("hotelId"),
          fdaction: fdaction,
          orderId: orderId,
          createTime: createTime.utc().format(),
          fdhour: Vue.ls.get("overNightHoursInfo"),
          hotelDeptId: request.hotelDeptId,
          inquiryNum: request.num,
          notes: "",
          requestType: Vue.ls.get("type"),
          roomNumber: this.roomNumber,
          requestTime: requestTime.utc().format(),
          serviceKey: request.keyword,
          serviceType: request.serviceType,
          phone: this.phone,
          status: "Pending",
          createTimeValue: time,
          tid: encryptedDES(time.substring(time.length - 8)),
          language: this.language,
        };
        if (
          Vue.ls.get("overNightHoursInfo") == "Front Desk Hour" ||
          Vue.ls.get("overNightHoursInfo") == "Cycle Front Desk Hour"
        ) {
          param.fdhour = Vue.ls.get("fdOption");
        } else if (
          Vue.ls.get("overNightHoursInfo") == "Cycle Regular Operating Hour"
        ) {
          param.fdhour = "Regular Operating Hour";
        }
        params.push(param);
      }
      params[0].notes = this.notes;
      if (this.notes.length > 200 && this.notes.length != 0) {
        Toast({
          message:
            `<div style="word-break: normal;font-size:14px;width=300px"><span>` +
            this.stringList.limit_description +
            `</span></div>`,
          type: "html",
        });
        return;
      }
      if (!this.submiting) {
        this.submiting = true;
        postAction(this.url.multipleCreateOrder, params)
          .then((res) => {
            if (res.success) {
              this.submiting = false;
              let arr = this.requestList.filter((i) => {
                return i.num > 0;
              });
              let target = arr.find((i) => {
                return (
                  i.section != "TransferToFD" && i.section != "TransferToRS"
                );
              });
              if (target) {
                this.thankPop = true;
              } else {
                this.thankPop2 = true;
              }
            } else {
              this.submiting = false;
              Toast(res.msg);
            }
          })
          .catch((e) => {
            this.submiting = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.right_keywords_title {
  display: grid !important;
  text-align: right !important;
}
.right_keywords_item {
  text-align: right !important;
}
.header {
  /deep/.van-nav-bar__content {
    height: 50px;
    background-color: #f3f3f3;
    .van-nav-bar__title {
      font-size: 18px;
      line-height: 20px;
      font-family: $fontFamily;
      color: #4f4f4f;
      font-weight: 400;
    }
    .van-nav-bar__arrow {
      color: #bbbbbb;
      font-size: 22px;
    }
  }
}
.miniFont {
  font-size: 16px !important;
}
.miniFont2 {
  font-size: 15px !important;
}
.bottom {
  height: 104px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  .btn {
    height: 47px;
    width: 167px;
    border-radius: 20px;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    span {
      font-size: 16px;
      line-height: 18px;
      font-family: $fontFamily;
      color: #ffffff;
    }
  }
}
.right_content {
  /deep/.ant-select-selection__placeholder {
    text-align: right !important;
  }
  /deep/.van-field__control {
    text-align: right !important;
  }
  /deep/.ant-select {
    text-align: right !important;
    direction: rtl;
  }
  /deep/.ant-select-dropdown-menu-item {
    text-align: right !important;
  }
}
.container::-webkit-scrollbar {
  display: none;
}
.right_text_item {
  text-align: right !important;
}
.container {
  height: calc(100% - 155px);
  background-color: #fff;
  width: 100%;
  padding: 20px 30px;
  overflow-y: auto;

  .form-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    word-break: normal;
    position: relative;
    margin-bottom: $form-title-margin-bottom;

    span {
      color: #333333;
      font-size: 16px;
      line-height: 18px;
      font-family: $fontFamily;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
    }
  }

  .form {
    padding-left: 10px;
    word-break: normal;

    .required {
      position: relative;

      &:before {
        position: absolute;
        content: "*";
        left: -15px;
        width: 15px;
        height: 40px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .form-item {
      font-size: 15px;
      word-break: normal;
      text-align: left;
      margin-bottom: 20px;
      line-height: 17px;
      font-family: $fontFamily;

      .van-field {
        min-height: 40px;
        border-radius: 6px;
        color: #9a9a9a;
        background-color: #f3f3f3;
        width: 100%;
        padding: 0 15px;
        display: flex;
        align-items: center;
        height: 100%;
        font-family: $fontFamily;
        line-height: 17px;
        font-size: 15px;

        ::-webkit-input-placeholder {
          color: #9a9a9a !important;
        }
      }
      .placeholderColor {
        ::-webkit-input-placeholder {
          color: #323233 !important;
        }
      }

      .text {
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
        color: #4f4f4f;
      }

      .roomNum {
        width: 100%;
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
        color: #323233;
      }

      /deep/.ant-select-selection {
        border-color: transparent;
        background-color: #f3f3f3;
        border-radius: 6px;
        box-shadow: none !important;
      }
      /deep/.ant-select-selection__placeholder {
        color: #9a9a9a;
      }

      /deep/.ant-select-selection:hover {
        border-color: transparent;
        box-shadow: 0 0 0 1px #9a94c0 !important;
      }

      /deep/textarea {
        padding: 10px 0 !important;
      }
    }

    .form-item2 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .van-field {
        width: auto;
        flex: 1;
      }

      .van-stepper {
        display: flex;
        flex-flow: nowrap;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
      }

      /deep/.van-stepper--round .van-stepper__minus {
        background-color: #ffffff;
        color: #4f4f4f;
        border: 2px solid #4f4f4f;
      }

      /deep/.van-stepper--round .van-stepper__plus {
        background-color: #ffffff;
        color: #4f4f4f;
        border: 2px solid #4f4f4f;
      }

      /deep/.van-stepper__minus::before,
      /deep/.van-stepper__plus::before {
        height: 2px;
      }

      /deep/.van-stepper__minus::after,
      /deep/.van-stepper__plus::after {
        width: 2px;
      }

      /deep/.van-stepper--round .van-stepper__input {
        font-family: $fontFamily;
        font-size: 16px;
        // line-height: 22px;
        height: 24px !important;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .form-item2:last-child {
      margin-bottom: 0px;
    }

    .form-item2:first-child {
      margin-top: 20px;
    }

    .form-item3 {
      height: 40px;
      text-align: left;
      display: flex;
      align-items: center;

      span {
        line-height: 16px;
        font-size: 14px;
        word-break: normal;
        font-family: $fontFamily;
        color: #1e4380;
      }
    }
  }
}

/deep/.van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
.van-popup--bottom.van-popup--round {
  border-radius: 12px 12px 0 0;
}

.van-popup--center.van-popup--round {
  border-radius: 6px;
}

.pop {
  word-break: normal;
  text-align: left;
  max-height: 80vh;

  .pop-title {
    margin: 0 30px;
    height: 60px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 18px;
      line-height: 20px;
      color: #1e4380;
      font-weight: 600;
    }

    .icon {
      font-size: 22px;
      color: #bbbbbb;
      position: absolute;
      left: 0px;
    }

    .shopping {
      color: #bbbbbb;
      position: absolute;
      right: 0px;
    }

    .van-badge__wrapper {
      display: flex;
    }

    /deep/.van-badge--fixed {
      top: 5px;
    }

    /deep/.van-badge {
      font-family: $fontFamily;
    }
  }

  .pop-title3 {
    margin: 0 20px;
    padding-bottom: 10px;
    height: 70px;
    display: flex;
    align-items: end;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 20px;
      line-height: 22px;
      color: #1e4380;
      font-weight: 600;
    }
  }

  .pop-title2 {
    margin: 0 20px;
    padding-bottom: 10px;
    height: 70px;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 20px;
      line-height: 22px;
      color: #1e4380;
      font-weight: 600;
    }
  }

  .pop-container {
    padding: 0 30px;
    max-height: calc(80vh - 160px);
    overflow-y: auto;

    .pop-item {
      min-height: 50px;
      padding: 10px 0;
      border-top: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 22px;
        margin-right: 25px;
      }
      .text {
        flex: 1;
        color: #000000;
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
      }
      .icon {
        font-size: 18px;
        color: #bbbbbb;
      }

      &:first-child {
        border-top: 0px;
      }
    }

    .other-select {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      padding-left: 50px;

      .select-other {
        flex: 1;
        font-size: 15px;
        font-family: $fontFamily;
      }

      /deep/.ant-select-selection {
        border-color: transparent;
        background-color: #f3f3f3;
        border-radius: 6px;
        box-shadow: none !important;
      }
      /deep/.ant-select-selection__placeholder {
        color: #9a9a9a;
      }
      /deep/.ant-select-selection:hover {
        border-color: transparent;
        box-shadow: 0 0 0 1px #9a94c0 !important;
      }
    }

    .van-stepper {
      display: flex;
      flex-flow: nowrap;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }

    /deep/.van-stepper--round .van-stepper__minus {
      background-color: #ffffff;
      color: #4f4f4f;
      border: 2px solid #4f4f4f;
    }

    /deep/.van-stepper--round .van-stepper__plus {
      background-color: #ffffff;
      color: #4f4f4f;
      border: 2px solid #4f4f4f;
    }

    /deep/.van-stepper__minus::before,
    /deep/.van-stepper__plus::before {
      height: 2px !important;
    }

    /deep/.van-stepper__minus::after,
    /deep/.van-stepper__plus::after {
      width: 2px !important;
    }

    /deep/.van-stepper--round .van-stepper__input {
      font-family: $fontFamily;
      font-size: 16px;
      // line-height: 22px;
      height: 24px !important;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn-area {
    height: 100px;
    border-top: 2px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      height: 47px;
      width: 167px;
      border-radius: 20px;
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 16px;
        line-height: 18px;
        font-family: $fontFamily;
        color: #ffffff;
      }
    }
  }

  .pop-info {
    padding: 0 40px;
    .text {
      font-size: 15px;
      line-height: 19px;
      color: #000000;
      font-family: $fontFamily;
      margin-bottom: 10px;
    }

    .info-item {
      padding-left: 20px;
      font-size: 15px;
      line-height: 20px;
      color: #000000;
      font-family: $fontFamily;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #000;
        left: 0px;
        top: 7px;
      }
    }

    .info-item:last-child {
      margin-bottom: 20px;
    }

    /deep/.van-radio__label {
      font-size: 15px;
      line-height: 17px;
      color: #333333;
      font-family: $fontFamily;
    }

    /deep/.van-radio__icon--checked .van-icon {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}

/deep/.ant-select-dropdown {
  z-index: 9999;
  text-align: left;
}
/deep/.ant-select-dropdown-menu-item {
  font-size: 17px;
  color: #000000;
  line-height: 24px;
  font-family: $fontFamily;
  z-index: 10000;
}
/deep/.ant-select-dropdown-content {
  z-index: 10000;
}
/deep/.ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  background-color: #f3f3f3 !important;
}
/deep/.ant-select-dropdown-menu-item-selected {
  background-color: #f3f3f3 !important;
}
/deep/.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  background-color: #f3f3f3 !important;
}
/deep/ .ant-select-dropdown-menu {
  max-height: 250px !important;
}
/deep/.ant-select-dropdown-menu-item-disabled {
  color: #bbbbbb;
}
/deep/.otherSelect {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/deep/.otherSelect .ant-select-dropdown-menu {
  max-height: 200px !important;
}

/deep/.ant-select-dropdown-menu-item-disabled {
  color: #bbbbbb;
}
/deep/.van-field__body {
  line-height: 20px;
}
</style>
